<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CRow>
          <CCol sm="12">
            <CCard>
              <CCardHeader>
                <CCardTitle>Create a new Location </CCardTitle>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="6">
                    <CInput name="name" label="Name" placeholder="Enter the name of the location"
                      v-model="location.name" required />
                  </CCol>
                  <CCol sm="6">
                    <CInput name="website" label="Website" placeholder="Enter the website of the location"
                      v-model="location.website" />
                  </CCol>
                  <CCol sm="6">
                    <CInput name="name" label="CoC Nr" placeholder="Enter the Chamber of Commerce NR of the location"
                      v-model="location.coc_nr" required />
                  </CCol>
                  <CCol sm="6">
                    <CInput name="name" label="Tax Nr" placeholder="Enter the tax number of the location"
                      v-model="location.tax_nr" required />
                  </CCol>
                  <CCol sm="12">
                    <CTextarea name="note" label="Note" placeholder="Enter a note for the location"
                      v-model="location.note" />
                  </CCol>
                  <CCol sm="6">
                    <CInputFile name="image" label="Image" description="Maximum total upload size is 1MB.
                    Images can be compressed using: imagecompressor.com" v-on:change="setImage" required />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>

            <CCard>
              <CCardHeader>
                <CCardSubtitle>Contact Information</CCardSubtitle>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="4">
                    <CInput name="address" label="Address" placeholder="Enter the address of the location"
                      v-model="location.location_address" />
                  </CCol>
                  <CCol sm="4">
                    <CInput label="Zip Code" placeholder="Enter the zip code of the location"
                      v-model="location.location_zip_code" />
                  </CCol>
                  <CCol sm="4">
                    <CInput label="City" placeholder="Enter the city of the location"
                      v-model="location.location_city" />
                  </CCol>
                  <CCol sm="4">
                    <CInput label="Country" placeholder="Enter the country of the location"
                      v-model="location.location_country" />
                  </CCol>
                  <CCol sm="4">
                    <CInput name="mail" label="Mail" placeholder="Enter the mail of the location"
                      v-model="location.location_mail" />
                  </CCol>
                  <CCol sm="4">
                    <CInput name="telephone" label="Telephone" placeholder="Enter the telephone of the location"
                      v-model="location.location_telephone" />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>

            <CCard>
              <CCardHeader>
                <CCardSubtitle>Invoicing Information</CCardSubtitle>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="4">
                    <CInput name="address" label="Address" placeholder="Enter the address of the location"
                      v-model="location.invoice_address" />
                  </CCol>
                  <CCol sm="4">
                    <CInput label="Zip Code" placeholder="Enter the zip code of the location"
                      v-model="location.invoice_zip_code" />
                  </CCol>
                  <CCol sm="4">
                    <CInput label="City" placeholder="Enter the city of the location" v-model="location.invoice_city" />
                  </CCol>
                  <CCol sm="4">
                    <CInput label="Country" placeholder="Enter the country of the location"
                      v-model="location.invoice_country" />
                  </CCol>
                  <CCol sm="4">
                    <CInput name="mail" label="Mail" placeholder="Enter the mail of the location"
                      v-model="location.invoice_mail" />
                  </CCol>
                  <CCol sm="4">
                    <CInput name="telephone" label="Telephone" placeholder="Enter the telephone of the location"
                      v-model="location.invoice_telephone" />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>

            <CCard>
              <CCardHeader>
                <CCardSubtitle>Customers</CCardSubtitle>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="12">
                    <CTextarea name="customers" placeholder="Enter the numbers or names of the customers"
                      description="If you separate values with comma's, customers will be duplicated. E.g. 123A,456B will create two rooms."
                      v-model="location.customers" />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>

            <CCard>
              <CCardHeader>
                <CCardSubtitle>Terms & Conditions</CCardSubtitle>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="12">
                    <ckeditor :editor="editor" v-model="location.terms" :config="editorConfig"></ckeditor>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>

            <CCard>
              <CCardHeader>
                <CCardSubtitle>Price Agreements</CCardSubtitle>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="6">
                    <label class="datetime-label">Return Time</label>
                    <datetime format="HH:mm" type="time" placeholder="Select time" label="From"
                      v-model="location.return_time">
                      <template slot="before">
                        <CButton class="close-btn" variant="outline" type="submit" size="sm" color="danger"
                          @click="location.return_time = null">
                          <CIcon class="close-btn-icon" name="cil-ban" />
                        </CButton>
                      </template>
                    </datetime>
                    <div style="margin-bottom: 20px"></div>
                  </CCol>
                </CRow>

                <CRow v-for="(price_agreement, index) in location.price_agreements">
                  <CCol sm="12">
                    <h5>{{ price_agreement.product_type }} Pricing</h5>
                    <CInput name="type" type="hidden" v-model="price_agreement.product_type" />
                  </CCol>
                  <CCol sm="3">
                    <CInput prepend="€" name="price_three_hour" label="Three Hour" type="number"
                      v-model="price_agreement.price_three_hours" min="0" />
                  </CCol>
                  <CCol sm="3">
                    <CInput prepend="€" name="price_five_hour" label="Five Hour" type="number"
                      v-model="price_agreement.price_five_hours" min="0" />
                  </CCol>
                  <CCol sm="3">
                    <CInput name="price_one_day" label="One Day" type="number" prepend="€"
                      v-model="price_agreement.price_one_day" min="0" />
                  </CCol>
                  <CCol sm="3">
                    <CInput name="price_extra_day" label="Extra Days" type="number" prepend="€"
                      v-model="price_agreement.price_extra_day" min="0" />
                  </CCol>
                  <CCol sm="3">
                    <CInput name="tax_percentage" label="Tax %" type="number" prepend="%"
                      v-model="price_agreement.tax_percentage" />
                  </CCol>
                  <CCol sm="3">
                    <CInput name="deal" label="Deal %" type="number" prepend="%"
                      v-model="price_agreement.deal_percentage" min="0" />
                  </CCol>
                  <CCol sm="3">
                    <CInput name="insurance" label="Insurance" type="number" prepend="€"
                      v-model="price_agreement.insurance" />
                  </CCol>

                  <CCol sm="3">
                    <label for="" class="">Insurance Optional</label><br />
                    <CSwitch label="Insurance" v-bind="{ variant: '3d', shape: 'pill', size: 'lg' }" color="success"
                      :checked.sync="price_agreement.insurance_optional" variant="outline" />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>

            <CButton variant="outline" type="submit" color="primary" @click="createLocation">
              <CIcon name="cil-check-circle" />
              Create
            </CButton>
          </CCol>
        </CRow>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import { Datetime } from "vue-datetime";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "vue-datetime/dist/vue-datetime.css";
import LocationAPI from "/app/src/api/location.js";

export default {
  name: "Create",
  components: { Datetime },
  data: () => {
    return {
      locationAPI: new LocationAPI(),
      location: {
        price_agreements: [],
      },
      image: {},
      required_types: ["bicycle", "accessories", "e-bike"],
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "bold",
          "italic",
          "|",
          "link",
          "blockquote",
          "inserttable",
          "|",
          "bulletedlist",
          "numberedlist",
          "|",
          "undo",
          "redo",
        ],
      },
    };
  },
  created: function () {
    this.initializeRequiredTypes();
  },
  methods: {
    createLocation: function () {
      console.log(this.location);
      this.$store.dispatch("loading");
      let self = this;
      console.log(self.location);
      this.locationAPI
        .create(self.location, this.image)
        .then((result) => {
          this.$store.dispatch("stopLoading");
          self.$router.push({ path: `/manager/locations/${result.id}` });
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          this.$alert.show({ type: "danger", message: error });
        });
    },

    initializeRequiredTypes: function () {
      for (let i = 0; i < this.required_types.length; i++) {
        this.location.price_agreements.push({
          product_type: this.required_types[i],
          tax_percentage: 21,
          insurance_optional: true,
        });
      }
    },
    setImage: function (files, e) {
      this.image = files[0];
      console.log(this.image);
    },
  },
};
</script>
